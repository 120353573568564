<script setup>

</script>
<template>
    
    <div class="ajv layout layout--default">
        <DebugOverlay/>
        <DefaultHeader  ></DefaultHeader>
        <main class="content-wrapper">
            <NuxtErrorBoundary>
           <slot />
           <template #error="{ error }">
            <div class="sb-section">
                <p>An error occurred: {{ error }}</p>
                <button @click="() => clearError({ redirect: '/' })">
                    This will clear the error.
                </button>
            </div>
            </template>
           </NuxtErrorBoundary>
        </main>
        <DefaultFooter></DefaultFooter>
        <!-- <DefaultBackground /> -->
    </div>
   
</template>
<style lang="scss">
     @use '/assets/styles/scss/layouts/default';
</style>